import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styles from "./sectionSteps.module.scss"
import { useParallax } from "react-scroll-parallax"
import { IsMobileDevice } from "../../../../../utils"
import microMouthSecundary from "../../../SvgIcon/svg/asset-25.svg"

// Components
import SvgIcon from "../../../SvgIcon"
import Section from "../../../Section"
import Button from "../../../Button"
import StepSlider from "./StepSlider"

const SectionSteps = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [stepMobile, setStepMobile] = useState(0)
  const [viewh, setViewh] = useState(0)

  const speed1 = isMobile ? 0 : -3
  const speed2 = isMobile ? 0 : 0.5
  const speed3 = isMobile ? 0 : 1.5
  const speed4 = isMobile ? 0 : -1
  const speed5 = isMobile ? 0 : 1
  const scale1 = isMobile ? [0, 0] : [1, 0.8]
  const scale2 = isMobile ? [0, 0] : [0.85, 1]
  const scale3 = isMobile ? [0, 0] : [0.4, 1]
  const scale4 = isMobile ? [0, 0] : [1.5, 1]
  const scale5 = isMobile ? [0, 0] : [0.5, 1]
  const scale6 = isMobile ? [0, 0] : [1, 1.5]
  const rotate1 = isMobile ? [0, 0] : [0, 10]
  const translateX1 = isMobile ? ["0px", "0px"] : ["-80px", "0px"]
  const translateX2 = isMobile ? ["0px", "0px"] : ["20px", "-50px"]
  const translateX3 = isMobile ? ["0px", "0px"] : ["-10px", "0px"]
  const startScroll1 = viewh + 400
  const endScroll1 = viewh + 600

  useEffect(() => {
    setIsMobile(IsMobileDevice())
    setViewh(window.innerHeight)
  }, [])

  const parallaxDivisor = useParallax({
    easing: "easeOutQuad",
    translateX: [-10, 10],
  })

  const prlxnumber1 = useParallax({
    speed: speed1,
    scale: scale1,
    startScroll: startScroll1,
    endScroll: endScroll1,
  })

  const prlxnumber2 = useParallax({
    speed: speed1,
    scale: scale1,
    startScroll: startScroll1,
    endScroll: endScroll1,
  })

  const prlxnumber3 = useParallax({
    speed: speed1,
    scale: scale1,
    startScroll: startScroll1,
    endScroll: endScroll1,
  })

  const prlxtitle1 = useParallax({
    speed: speed2,
    startScroll: startScroll1,
    endScroll: endScroll1,
  })

  const prlxtitle2 = useParallax({
    speed: speed3,
    startScroll: startScroll1,
    endScroll: endScroll1,
  })

  const prlxtitle3 = useParallax({
    speed: speed3,
    startScroll: startScroll1,
    endScroll: endScroll1,
  })

  const prlxsvg1 = useParallax({
    speed: speed4,
    startScroll: startScroll1,
    endScroll: endScroll1,
  })

  const prlxsvg2 = useParallax({
    speed: speed5,
    scale: scale2,
    startScroll: startScroll1,
    endScroll: endScroll1,
  })

  const prlxsvg3 = useParallax({
    rotate: rotate1,
    scale: scale3,
    startScroll: startScroll1,
    endScroll: endScroll1,
  })

  const prlxdiscL = useParallax({
    scale: scale4,
    translateX: translateX1,
    startScroll: startScroll1 + 50,
    endScroll: endScroll1,
  })

  const prlxdiscR = useParallax({
    scale: scale4,
    translateX: translateX2,
    startScroll: startScroll1,
    endScroll: endScroll1,
  })

  const prlxstart1 = useParallax({
    scale: scale5,
    startScroll: startScroll1,
    endScroll: endScroll1,
  })

  const prlxpaz1 = useParallax({
    scale: scale6,
    translateX: translateX3,
    startScroll: startScroll1 + 100,
    endScroll: endScroll1,
  })

  return (
    <Section sectionName="steps" className={styles.steps}>
      {isMobile ? (
        <>
          <div className={styles.divisor}>
            <SvgIcon
              name="divisorBud"
              size="350vw"
              className={styles.divisor2}
            />
          </div>
          <StepSlider handleChange={setStepMobile} />
          <div className={styles[`stepsMobile${stepMobile}`]}>
            <SvgIcon
              key="pacemob"
              name="peace-icon"
              size="2.941vw"
              className={styles.peacemob}
            />
            <SvgIcon
              key="dismob1"
              name="disc-icon"
              size="34.490vw"
              className={styles.discomobl}
            />
            <SvgIcon
              key="dismoc2"
              name="disc-icon"
              size="35.321vw"
              className={styles.discomobr}
            />
            <SvgIcon
              key="starmob"
              name="star-icon"
              size="13.090vw"
              className={styles.startmob1}
            />
          </div>
          <SvgIcon
              key="arrowicon"
              name="arrow-icon"
              size="13vw"
              className={styles.arrowicon}
            />
        </>
      ) : (
        <>
          <SvgIcon name="bud-redform-icon-ligth" size="89.722vw" />
          <div ref={parallaxDivisor.ref} className={styles.divisor}>
            <SvgIcon
              name="divisorBud"
              size="100vw"
              className={styles.divisor2}
            />
            <SvgIcon
              name="divisorBud"
              size="100vw"
              className={styles.divisor2}
            />
          </div>
          <SvgIcon
            name="peace-icon"
            size="1.392vw"
            className={styles.flotaIcon1}
            rf={prlxpaz1.ref}
          />
          <SvgIcon
            name="peace-icon"
            size="1.392vw"
            className={styles.flotaIcon2}
          />
          <SvgIcon
            name="disc-icon"
            size="7.294vw"
            className={styles.flotaIcon3}
            rf={prlxdiscL.ref}
          />
          <SvgIcon
            name="disc-icon"
            size="8.228vw"
            className={styles.flotaIcon4}
            rf={prlxdiscR.ref}
          />
          <SvgIcon
            name="star-icon"
            size="4.351vw"
            className={styles.flotaIcon5}
          />
          <SvgIcon
            name="star-icon"
            size="7.351vw"
            className={styles.flotaIcon6}
            rf={prlxstart1.ref}
          />
          <div className={styles.wrapper}>
            <div className={styles.container1}>
              <SvgIcon
                name="caricature-icon"
                size="8.056vw"
                className={styles.icon1}
                rf={prlxsvg1.ref}
              />
              <span ref={prlxnumber1.ref} className={styles.n1}>
                1.
              </span>
              <h1 ref={prlxtitle1.ref}>
                GRABÁ <br />
                TU VIDEO
              </h1>
              <p>
                Filmate a vos o con tu banda <br /> tocando un tema suyo.
              </p>
            </div>

            <div className={styles.container2}>
              <SvgIcon
                name="fire-lips-icon"
                size="8.056vw"
                className={styles.icon2}
                rf={prlxsvg2.ref}
              />
              <span ref={prlxnumber2.ref}>2.</span>
              <h1 ref={prlxtitle2.ref}>
                SUBÍ TU <br />
                TEMA Y <br />
                PARTICIPÁ
              </h1>
              <p>
                Compartí tu tema y <br />
                difundilo para recibir votos.
              </p>
            </div>

            <div className={styles.container3}>
              <img
                className={styles.icon3}
                src={microMouthSecundary}
                ref={prlxsvg3.ref}
              />
              <span ref={prlxnumber3.ref}>3.</span>
              <h1 ref={prlxtitle3.ref}>
                TOCÁ EN <br />
                VIVO EN <br />
                CAMPING BA
              </h1>
              <p>
                El ganador tocará como <br /> telonero en Camping BA.
              </p>
            </div>
          </div>
          {/*<Button
            onClick={() => window.location.replace("/budstage/participate")}
            label="EMPECEMOS"
            type="secondary"
            className={styles.btnStart}
          />*/}
        </>
      )}
    </Section>
  )
}

export default SectionSteps
