import React, { useState, useEffect } from "react"
import styles from "./sectionInfo.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import { useParallax } from "react-scroll-parallax"
import { IsMobileDevice } from "../../../../../utils"

// Components
import Section from "../../../Section"
import SvgIcon from "../../../SvgIcon"

import imageDesktop from "../../../../../images/BudStage/home/duo-image.png"
import imageMobile from "../../../../../images/BudStage/home/duo-mobile.png"

const SectionInfo = () => {
  const [isMobile, setIsMobile] = useState()
  const parallaxImage = useParallax({
    speed: -5,
  })
  const parallaxText = useParallax({
    speed: 4,
  })

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  })

  return (
    <Section className={styles.SectionInfo}>
      <SvgIcon name="textura-fondo" size="50vw" className={styles.fondo} />
      {isMobile ? (
        <div className={styles.imageSectionBody}>
          <img src={imageMobile} />
          <div className={styles.imageSectionText}>
            <p>
              VOTANDO PARTICIPÁS AUTOMÁTICAMENTE POR UN PASE ANUAL EN CAMPING BA
            </p>
            <p>
              Si en lugar de tocar música, sos fanático de escucharla y
              cantarla, también podes participar. Votá tu tema favorito y
              participá por un pase anual para los shows en Camping BA y merch
              oficial de Budweiser.
            </p>
            <p>
              BUDWEISER<span>X</span>BUD STAGE
            </p>
          </div>
        </div>
      ) : (
        <div className={styles.imageSectionBody}>
          <div ref={parallaxText.ref} className={styles.imageSectionText}>
            <p>
              VOTANDO PARTICIPÁS AUTOMÁTICAMENTE POR UN PASE ANUAL EN CAMPING BA
            </p>
            <p>
              Si en lugar de tocar música, sos fanático de escucharla y
              cantarla, también podes participar. Votá tu tema favorito y
              participá por un pase anual para los shows en Camping BA y merch
              oficial de Budweiser.
            </p>
            <p>
              BUDWEISER<span>X</span>BUD STAGE
            </p>
          </div>

          <div className={styles.backgroundSquare}>
            <span ref={parallaxImage.ref}>
              <img src={imageDesktop} />
            </span>
          </div>
        </div>
      )}
    </Section>
  )
}

export default SectionInfo
