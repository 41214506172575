import React, { useState, useEffect } from "react"
import Section from "../../../Section"
import SvgIcon from "../../../SvgIcon"
import Button from "../../../Button"
import Title from "../Title"
import styles from "./sectionWinner.module.scss"
import { IsMobileDevice } from "../../../../../utils"

const SectionWinner = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  const handleRedirect = () => {
    window.location.replace("https://www.instagram.com/budweiser_ar/")
  }

  return (
    <Section className={styles.winnerSection}>
      {isMobile ? (
        <div className={styles.winnerSectionBody}>
          <div className={styles.winnerHeader}>
            <SvgIcon
              name="micro-mouth-icon"
              className={styles.winnerMouthIcon}
              size="33.333vw"
            />
            <Title title="GANADOR/ES" x />
          </div>
          <div>
            <p>
              El artista o la banda ganadora se anunciará el 14/06/2022. <br />{" "}
              Seguí las redes de BUD para no perderte de nada y descubrir a tu
              nuevo artista favorito.
            </p>
            <Button
              label="ENTERATE ACÁ"
              type="secondary"
              onClick={handleRedirect}
            />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.winnerSectionBody}>
            <SvgIcon name="micro-mouth-icon" size="10.526vw" />
            <Title title="GANADOR/ES" x />
            <div>
              <p>
                El artista o la banda ganadora se anunciará el 14/06/2022.{" "}
                <br /> Seguí las redes de BUD para no perderte de nada y
                descubrir a tu nuevo artista favorito.
              </p>

              <Button
                label="ENTERATE ACÁ"
                type="secondary"
                onClick={handleRedirect}
              />
            </div>
          </div>
        </>
      )}
    </Section>
  )
}

export default SectionWinner
