import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { divideData } from "./utils"
import styles from "./sectionLastCovers.module.scss"
import { IsMobileDevice } from "../../../../../utils"

// Components
import Title from "../Title"
import Slider from "../../../Slider"
import SvgIcon from "../../../SvgIcon"
import Section from "../../../Section"

const SectionLastCovers = props => {
  const { data } = props
  const [isMobile, setIsMobile] = useState(false)
  const [dataSlider1, dataSliders2] = divideData(data)


  useEffect(async () => {
    setIsMobile(IsMobileDevice())
  }, [])

  const goToCovers = () => {
    window.location.replace("/budstage/temas")
  }

  const onCardClick = id => {
    return window.location.replace(`/budstage/tema/?id=${id}`)
  }

  return (
    <Section sectionName="lastCovers" className={styles.sliderSection}>
      <div className={styles.sliderSectionHeader}>
        <Title title="ÚLTIMOS TEMAS" x />
        <span onClick={goToCovers}>
          VER TODOS{" "}
          <SvgIcon name="arrow-icon" size={isMobile ? "9.049vw" : "3.566vw"} />
        </span>
      </div>
      <div className={styles.slidersContainer}>
        {data.length > 10 ? (
          <>
            <Slider
              dataSource={dataSlider1}
              className={styles.lastCoversSlider}
              onClick={onCardClick}
              slidesPerView={isMobile ? 1.3 : 5}
              spaceBetween={isMobile ? 0 : 20}
            />
            <Slider
              dataSource={dataSliders2}
              className={styles.lastCoversSlider}
              onClick={onCardClick}
              slidesPerView={isMobile ? 1.3 : 5}
              spaceBetween={isMobile ? 0 : 20}
            />
          </>
        ) : isMobile ? (
          <>
            <Slider
              dataSource={dataSlider1}
              className={styles.lastCoversSlider}
              onClick={onCardClick}
              slidesPerView={isMobile ? 1.5 : 5}
              spaceBetween={isMobile ? 0 : 20}
            />
            <Slider
              dataSource={dataSliders2}
              className={styles.lastCoversSlider}
              onClick={onCardClick}
              slidesPerView={isMobile ? 1.5 : 5}
              spaceBetween={isMobile ? 0 : 20}
            />
          </>
        ) : (
          <Slider
            dataSource={data}
            className={styles.lastCoversSlider}
            onClick={onCardClick}
            slidesPerView={isMobile ? 1.3 : 5}
            spaceBetween={isMobile ? 0 : 20}
          />
        )}
      </div>
    </Section>
  )
}

export default SectionLastCovers
