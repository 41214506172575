import React, { useState, useEffect } from "react"
import styles from "./home.module.scss"
import { search } from "../../../services"

// Home Components
import Layout from "../Layout"
import SvgIcon from "../SvgIcon"
import SectionInit from "./components/sectionInit"
import SectionLastCovers from "./components/sectionLastCovers"
import SectionSteps from "./components/sectionSteps"
import SectionAwards from "./components/sectionAwards"
import SectionInfo from "./components/sectionInfo"
import SectionJury from "./components/sectionJury"
import SectionWinner from "./components/sectionWinner"

const HomePage = ({ images }) => {
  const [data, setData] = useState([])
  const dataGraphql = images

  useEffect(() => {
    search("*", 20, 1, null, "DESC").then(setData)
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const id = window.location.hash.replace("#", "") || ""
    const element = document.getElementById(id)
    if (id && element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  })

  return (
    <main className={styles.pageContainer}>
      <Layout>
        <SectionInit />
        <SvgIcon name="textura-fondo" size="47vw" className={styles.fondo} />
        <SectionLastCovers data={data} />
        <SectionSteps />
        <SectionAwards imagenes={dataGraphql} />
        <SectionInfo imagenes={dataGraphql} />
        <SectionJury imagenes={dataGraphql} />
        <SectionWinner />
      </Layout>
    </main>
  )
}

export default HomePage
