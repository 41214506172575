import React, { useState, useEffect } from "react"
import styles from "./sectionInit.module.scss"
import { useParallax } from "react-scroll-parallax"
import { IsMobileDevice } from "../../../../../utils"

// Components
import Button from "../../../Button"
import SvgIcon from "../../../SvgIcon"
import Section from "../../../Section"
import BudCover from "../../../TextBudStage"

const SectionInit = () => {
  const [isMobile, setIsMobile] = useState(false)

  // const prlxCaricature = useParallax({
  //   translateX: ["0px", "100px"],
  //   startScroll: 0,
  //   endScroll: 400,
  // })

  const prlxBud = useParallax({
    speed: 1,
    startScroll: -400,
    endScroll: 400,
  })

  const prlxsub = useParallax({
    speed: -1,
    startScroll: -400,
    endScroll: 400,
  })

  const prlxinfo = useParallax({
    speed: 4,
    startScroll: -400,
    endScroll: 400,
  })

  const prlxcap = useParallax({
    rotate: [-220, 0],
    scale: [0.85, 1.25],
    startScroll: -400,
    endScroll: 400,
  })

  const prlxdiscL = useParallax({
    scale: [1, 1.5],
    startScroll: -400,
    endScroll: 400,
  })

  const prlxdiscR = useParallax({
    rotate: [0, -220],
    scale: [1, 1.5],
    startScroll: -400,
    endScroll: 400,
  })

  const prlxStart = useParallax({
    scale: [0.15, 1.5],
    startScroll: -400,
    endScroll: 400,
  })

  const onClickParticipate = () => {
    window.location.replace("/budstage/participate")
  }

  const onClickCovers = () => {
    window.location.replace("/budstage/temas")
  }

  const handleScroll = () => {
    window.location.replace("/budstage/#lastCovers")
  }

  const handleTyc = () => {
    window.location.replace("/budstage/tyc")
  }

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  return (
    <Section className={styles.flexInit}>
      {isMobile ? (
        <div className={styles.mobile}>
          <div>
            <p>BUD</p>
            <p>STAGE</p>
          </div>
          <p>
            <SvgIcon name="bud-whiteform-icon" size="9.038vw" />
            EL MAÑANA EN TUS MANOS
          </p>
          <p>
            Si sos de los que creen que el mañana está en sus manos, participá
            de BUD STAGE. <br /> Subí tu video interpretando un tema tuyo o de
            tu banda y participá por tocar en vivo junto a grandes artistas en
            Camping. <br />
            Además, ganá merch y entradas votando tus videos favoritos.
          </p>
          <div className={styles.buttonWrapper}>
           {/* <Button
              label="SUBÍ TU TEMA"
              type="secondary"
              onClick={onClickParticipate}
            />
            <Button label="VOTÁ" type="primary" onClick={onClickCovers} />
            <SvgIcon
              name="arrow-down"
              size="13.054vw"
              className={styles.arrowIcon}
              onClick={handleScroll}
            />*/}
          </div>
          <SvgIcon name="cap-icon" size="13.278vw" className={styles.capIcon} />
        </div>
      ) : (
        <>
          <SvgIcon
            name="bud-redform-icon-ligth"
            className={styles.redFormBack}
          />
          <SvgIcon
            name="disc-icon"
            size="5.278vw"
            className={styles.discLeft}
            rf={prlxdiscL.ref}
          />
          <SvgIcon
            name="disc-icon"
            size="6.278vw"
            className={styles.discRigth}
            rf={prlxdiscR.ref}
          />
          <SvgIcon
            name="star-icon"
            size="6.278vw"
            className={styles.start}
            rf={prlxStart.ref}
          />
          <BudCover orientation="vertical" className={styles.budTextVertical} />
          <div className={styles.center}>
            <div className={styles.budCover}>
              <p ref={prlxBud.ref}>
                BUD <br /> STAGE
              </p>
              {/* <SvgIcon
                name="caricature-icon"
                size="12.986vw"
                className={styles.caricature}
                rf={prlxCaricature.ref}
              /> */}
              <p ref={prlxsub.ref}>
                <SvgIcon name="bud-red-icon" size="3.264vw" />
                EL MAÑANA EN TUS MANOS
              </p>
            </div>
            <div className={styles.info} ref={prlxinfo.ref}>
              <p>
                {/* <SvgIcon name="bud-red-icon" size="1.667vw" />
                THE BIRRA BEHIND THE MUSIC */}
              </p>
              <p>
                Si sos de los que creen que el mañana está en sus manos,
                participá de BUD STAGE. <br /> Subí tu video interpretando un
                tema tuyo o de tu banda y participá por tocar en vivo junto a
                grandes artistas en Camping.
                <br /> Además, ganá merch y entradas votando tus videos
                favoritos.
              </p>
              <div className={styles.buttonWrapper}>
              {/*  <Button
                  label="SUBÍ TU TEMA"
                  type="secondary"
                  onClick={onClickParticipate}
                />
                <Button label="VOTÁ" type="primary" onClick={onClickCovers} />*/}
              </div>
            </div>
          </div>
          <SvgIcon
            name="cap-icon"
            size="6.278vw"
            className={styles.capIcon}
            rf={prlxcap.ref}
          />
          <SvgIcon
            name="arrow-down"
            size="3.542vw"
            className={styles.arrowIcon}
            onClick={handleScroll}
          />
          <div className={styles.weBrewIcon} onClick={handleTyc}>
            <a>VER BASES Y CONDICIONES</a>
            <SvgIcon name="we-brew-icon" size="4.306vw" />
          </div>
        </>
      )}
    </Section>
  )
}

export default SectionInit
