import React from "react"
import styles from "./section.module.scss"
import { node, string } from "prop-types"

const Section = props => {
  const { children, className, sectionName } = props

  return (
    <section id={`${sectionName}`} className={`${styles.section} ${className}`}>
     {children}
    </section>
  )
}

Section.defaultProps = {
  className: "",
}

Section.propTypes = {
  children: node.isRequired,
  className: string,
}

export default Section
