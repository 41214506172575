import React, { useState, useEffect } from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import Cookies from "js-cookie"

//Components
import SEO from "../../components/seo"
import AgeGate from "../../components/AgeGate"
import HomePage from "../../components/BudStage/Home/home"

const BudStage = ({ data }) => {

  const dataGraphql = data.allFile.edges
  const [age, setAge] = useState(true)

  useEffect(() => {
    let td = Cookies.get("_td")
    window.dataLayer.push({
      brand: "Budweiser",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: "Budweiser BudStage",
      cms: "1",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Bud Stage",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: td,
      url: "https://www.budweiser.com.ar/budstage",
      url_campaign: "budweiser_budstage",
      Step_number: "",
    })
  }, [])


  // console.log("dataaaaa", dataGraphql)
  useEffect(() => {
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    if (cookie || local) setAge(false)
  }, [])

  return !age ? (
    <ParallaxProvider>
      <SEO title="Bud Stage" />
      <HomePage images={dataGraphql} />
    </ParallaxProvider>
  ) : (
    <AgeGate path="/budstage" />
  )
}

export default BudStage

export const data = graphql`
  query budStage {
    allFile(filter: { relativeDirectory: { eq: "BudStage" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
